<template>
  <div class="customerConfig">
    <div class="title">Customer Config Settings</div>
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Enterprise Name:" size="">
          <template>
            <el-input
              v-model.trim="Contractinput"
              placeholder="Pleas Enter"
              size=""
            />
          </template>
        </el-form-item>
        <el-button type="" @click="specialReset">Reset</el-button>
        <el-button type="primary" icon="el-icon-search" @click="Search">
          Search
        </el-button>
      </el-form>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin-top: 32px"
        @click="CreateProduct"
      >
        Add
      </el-button>
    </div>
    <div class="config">
      <coustomerDialog ref="childrenDom" />
      <el-table
        :data="tableList"
        class="promotionList"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column label="Enterprise Name" min-width="200">
          <template slot-scope="scope">
            <span>
              {{ scope.row.enterpriseName || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="URL" min-width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.url || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Logo" min-width="300">
          <template slot-scope="scope">
            <img
              :src="scope.row.logo.indexOf('http') == -1 ? imgHeaderInit + scope.row.logo : scope.row.logo"
              width="96px"
              style="cursor: pointer"
              @click="handlePreview(scope.row.logo.indexOf('http') == -1 ? imgHeaderInit + scope.row.logo : scope.row.logo)"
            >
          </template>
        </el-table-column>
        <el-table-column label="Operation" width="127" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleUpdate(scope.row)"
            >
              Edit
            </el-button>
            <el-button type="text" size="small" @click="handleDel(scope.row)">
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
          :current-page="listQuery.page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- 修改 -->
    <el-dialog title="Update Logo" :visible.sync="dialogVisible" width="508px">
      <span>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="150"
          class="demo-ruleForm"
        >
          <el-form-item label="Enterprise Name" prop="enterpriseName">
            <el-input
              v-model="ruleForm.enterpriseName"
              size="small"
              placeholder="Please enter"
              disabled
            />
          </el-form-item>
          <!-- <el-form-item label="Enterprise ID" prop="enterpriseId">
            <el-input
              v-model="ruleForm.enterpriseId"
              size="small"
              placeholder="Please enter"
              :disabled="Enterprise"
              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            />
          </el-form-item> -->

          <el-form-item label="Image" prop="logo">
            <el-upload
              ref="my-upload"
              :action="http"
              :class="{ hideUploadEdit: showBtnDealImg }"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="codeRemove"
              :on-change="codeChange"
              :on-success="success"
              :on-error="errorImgFile"
              :file-list="fileList"
              :limit="limitCode"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <p class="centerText">
              <i class="el-icon-warning-outline" /> Size should be 1000×500px
            </p>
          </el-form-item>

          <el-form-item label="URL" prop="url">
            <el-input v-model="ruleForm.url" placeholder="url" maxlength="150">
              <template slot="prepend" />
            </el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelForm('ruleForm')">Cancel</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">Confirm
        </el-button>
      </span>
    </el-dialog>

    <!-- 列表里面的缩略图 -->
    <el-dialog :visible.sync="dialogListVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageListUrl"
        alt=""
      >
    </el-dialog>
    <!-- 缩略图 -->
    <el-dialog :visible.sync="dialogImageVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>

<script>
import { customer, customerDelete, customerEitd } from '@/api/special/api';
import coustomerDialog from './coustomerDialog';
import {image_base_url} from '@/common/config/index.js'
export default {
  name: 'SustomerConfig',
  components: {
    coustomerDialog
  },
  data() {
    return {
      emptyImgSrc: '',
      imgHeaderInit: image_base_url,
      showBtnDealImg: false,
      limitCode: 1,
      // 表格数据
      tableList: [],
      //loding
      loading: true,
      ruleForm: {
        enterpriseName: '',
        log: '',
        url: undefined
      },
      Enterprise: false,
      fileList: [],
      //   表单验证
      rules: {
        enterpriseName: [
          {
            required: true,
            message: 'Enter the business name',
            trigger: 'change'
          }
        ],
        // enterpriseId: [
        //   {
        //     required: true,
        //     message: 'Enter the business id',
        //     trigger: 'change'
        //   }
        // ]
      },
      http: process.env.VUE_APP_BASE_API + '/system/ware/file/uploadImg',
      //分页信息
      listQuery: {
        pageSize: 10,
        page: 1,
        total: 0
      },
      Contractinput: null,
      dialogVisible: false,
      dialogImageVisible: false,
      dialogListVisible: false,
      trues: true,
      codeFile: '',
      codeFileList: '',
      hideCode: false,
      linmitCode: 1,
      // 列表dialog地址
      dialogImageListUrl: '',
      dialogImageUrl: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getList();
  },
  methods: {
    //重置
    specialReset() {
      this.Contractinput = null;
      this.getList();
    },
    //搜索
    Search() {
      //console.log(this.Contractinput);
      this.listQuery.page = 1;
      this.getList();
    },
    //显示的数量改变
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getList();
    },
    //页数的改变
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handlePreview(file) {
      this.dialogImageListUrl = file;
      this.dialogListVisible = true;
    },

    //获取信息列表
    getList() {
      customer(
        Object.assign({
          pageNo: this.listQuery.page,
          pageSize: this.listQuery.pageSize,
          enterpriseName: this.Contractinput
        })
      )
        .then(res => {
          if (res.success && res.code === 200) {
            this.tableList = res.data.dataList;
            this.listQuery.total = res.data.totalCount;
          } else {
            this.tableList = [];
            this.listQuery.total = 0;
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
        .catch(() => {
        });
    },
    //创建客户配置信息
    CreateProduct() {
      this.$refs.childrenDom.dialogVisible = true;
    },

    //提交验证调用添加接口
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let obj = {
            // enterpriseId: this.ruleForm.enterpriseId,
            enterpriseName: this.ruleForm.enterpriseName,
            id: this.ruleForm.id,
            logo: this.ruleForm.logo,
            state: this.ruleForm.state,
            url: this.ruleForm.url
          }
          customerEitd(obj).then(res => {
            this.$refs[formName].resetFields();
            this.dialogVisible = false;
            this.getList();
          });
        } else {
          return false;
        }
      });
    },

    // 点击取消
    cancelForm(formName) {
      this.dialogVisible = false;
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },

    handleRemove() {},

    success(response, file, fileList) {
      this.ruleForm.logo = response.msg;
      this.checkoutImgFn()
    },
    // 检验图片的方法
    checkoutImgFn() {
      let than = this;
      var height = 500;
      var width = 1000;
      let image = new Image();
      image.src = this.imgHeaderInit + this.ruleForm.logo;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          than.$message.error('Please upload 1000 × 500 pictures');
          than.ruleForm.logo = '';
          than.flieList = [];
          than.codeRemove({},than.flieList)
          return;
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            than.$message.error('Please upload 1000 × 500 pictures');
            than.ruleForm.logo = '';
            than.flieList = [];
            than.codeRemove({},than.flieList)
            return;
          }
        };
      }
    },
    errorImgFile(response) {
      this.ruleForm.logo = '';
      this.flieList = [];
      this.codeRemove({},this.flieList)
      this.$message({ message: response, type: 'error' });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      if (!isJPG) {
        this.$message.error('Uploaded images can only be in JPG or PNG format!');
      }
      return isJPG;
    },
    handleDel(row) {
      this.$confirm(
        'This operation will permanently delete the file. Do you want to continue?',
        {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel ',
          type: 'warning'
        }
      )
        .then(() => {
          customerDelete(row.id).then(res => {
            this.getList();
            this.$message({
              message: 'Deleted successfully',
              type: 'success'
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancelled delete'
          });
        });
    },
    handleUpdate(row) {
      this.trues = false;
      this.Enterprise = true;
      this.ruleForm = [];
      this.fileList = [];
      this.dialogVisible = true;
      let datas = JSON.parse(JSON.stringify(row));
      if (row.logo.indexOf('http') == -1) {
        this.fileList.push({ url: this.imgHeaderInit + row.logo });
      } else {
        this.fileList.push({ url: row.logo });
      }
      // this.ruleForm.enterpriseId = datas.enterpriseId;
      // this.ruleForm.enterpriseName = datas.enterpriseName;
      // this.ruleForm.logo = datas.logo;
      // this.ruleForm.url = datas.url;
      this.ruleForm = datas;
      this.showBtnDealImg = this.fileList.length >= this.limitCode;
    },
    // 图片上传时清楚校验规则
    codeChange(file, fileList) {
      this.codeFile = file;
      this.codeFileList = fileList;
      if (fileList.length == 1) {
        let { logo, ...data } = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('logo');
      this.showBtnDealImg = fileList.length >= this.limitCode;
    },
    codeRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.logo = [
          { required: true, message: 'upload pictures', trigger: 'change' }
        ];
      }
      this.showBtnDealImg = fileList.length >= this.limitCode;
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.customerConfig {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .title {
    height: 66px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    padding: 24px 16px;
    font-size: 16px;
    font-weight: Bold;
    line-height: 17px;
  }
  .serch_box {
    width: 100%;
    background: #fff;
    padding: 24px 16px 18px;
    ::v-deep .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .el-input {
      margin-right: 40px;
    }
    ::v-deep.el-input__inner {
      height: 32px;
    }
  }
  .fixed_form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      display: flex;
      //flex-direction: column;
      margin-bottom: 0;
      /deep/.el-form-item__label {
        text-align: left;
      }
    }
  }
  /deep/.el-button {
    // padding: 0 12px !important;
    width: 100px;
    font-family: ArialMT;
    font-size: 14px;
  }
  ::v-deep .el-table {
    .cell {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  ::v-deep .el-pager {
    li {
      border: 0;
    }
  }
  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
  ::v-deep .el-button--text {
    color: #2262ff;
    text-align: left;
    margin-left: 0;
  }

  .config {
    background: white;
    padding: 0 16px 50px;

    .promotionList {
      width: 100%;
      margin: 0;
    }
    .pages {
      float: right;
      .el-pagination {
        margin: 10px 2px 0 0;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 8px 16px 16px;
  }
  ::v-deep .hideUploadEdit {
    .el-upload--picture-card {
      display: none;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      line-height: 146px;
      vertical-align: top;
    }
  }
}
.centerText {
  margin-left: 60px;
  font-size: 14px;
  font-family: ArialMT;
  color: #9398a4;
}
</style>
