<template>
  <div class="companies-list">
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Enterprise Name:" size="">
          <template>
            <el-input
              v-model.trim="formList.enterpriseName"
              placeholder="Pleas enter the Enterprise ID"
              size=""
            />
          </template>
        </el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          style="margin-top: 4px;margin-left:20px"
          @click="searchBtn"
        >
          Search
        </el-button>
      </el-form>
    </div>
    <div class="panel-body">
      <el-table
        v-loading="loadingTwo"
        :data="fileList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799;">No more data</p>
        </template>
        <el-table-column
          label="Enterprise Name"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName || '--' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Saler Erp" min-width="">
          <template slot-scope="scope">
            <span>{{ scope.row.salerErp || '--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="Opearte"
          width="150"
          fixed="right"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-button
            
              type="primary"
              size="mini"
              @click="seletBtn(scope.row)"
            >
              select
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>    
  </div>
</template>

<script>
import {
  getEnterprise,
} from '@/api/user/list';
export default {
  name: 'CompaniesList',
  data() {
    return {
      emptyImgSrc: '',
      formList: {
        enterpriseName: ''
      },
      // 弹窗中表格分页
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      fileList: [],
      loadingTwo: false,
    };
  },
  mounted() {
        
  },
  created () {
    this.getEnterprise();
  },
  methods: {
    // 获取日志列表
    getEnterprise() {
      getEnterprise(
        {
          pageNo: this.listQuery.pageCode,
          pageSize: this.listQuery.pageSize,
          enterpriseInfoDTO:{...this.formList}
        }
      ).then(res => {
        this.loadingTwo = false;
        if (res.success && res.code === 200) {
          this.fileList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          this.fileList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    seletBtn(row) {
      this.$emit('seletBtn', row);
    },
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getEnterprise();
    },
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getEnterprise();
    },
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getEnterprise();
    },
  },
};
</script>

<style lang="less" scoped>
.companies-list{
   width: 100%;
   height: 100%;
  .serch_box {
    width: 100%;
    border-radius: 5px;
    background: #fff;
    box-sizing: border-box;
    ::v-deep .el-input__inner {
        height: 32px;
        line-height: 30px;
    }
   }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    display: flex;
    justify-content: flex-end;
    height: 45px;
    width: 100%;
  }
}
</style>