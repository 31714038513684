<template>
  <div class="specialDialog">
    <!-- 创建 -->
    <el-dialog title="Create Logo" class="createLogoContainer" :visible.sync="dialogVisible" width="800px">
      <span class="dialog-top">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="150"
          class="demo-ruleForm"
        >
          <el-form-item label="Enterprise Name" prop="enterpriseName">
            <el-input
              v-model="ruleForm.enterpriseName"
              size="small"
              placeholder="Please select from the list below"
              disabled
            />
          </el-form-item>
          <!-- <el-form-item label="Enterprise ID" prop="enterpriseId">
            <el-input
              v-model="ruleForm.enterpriseId"
              disabled
              size="small"
              placeholder="Please select from the list below"
            />
          </el-form-item> -->

          <el-form-item label="Image" prop="logo">
            <el-upload
              ref="my-upload"
              :class="{ hideUploadEdit: showBtnDealImg }"
              :action="http"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-error="errorImgFile"
              :on-remove="codeRemove"
              :on-change="codeChange"
              :on-success="successFile"
              :file-list="flieList"
              :limit="limitCode"
              :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <p class="centerText">
              <i class="el-icon-warning-outline" /> Size should be 1000×500px
            </p>
          </el-form-item>

          <el-form-item label="URL" prop="url">
            <el-input v-model.trim="ruleForm.url" placeholder="url">
              <template slot="prepend" />
            </el-input>
          </el-form-item>
        </el-form>
      </span>
      <span class="dialog-footer">
        <el-button @click="cancelForm('ruleForm')">Cancel</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">Confirm
        </el-button>
      </span>
      <div class="companiesListCont">
        <companies-list @seletBtn="seletFromFn" />
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogImageVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>

<script>
import { Add } from '@/api/special/api';
import CompaniesList from '@/components/enterpriseComponents/CompaniesList.vue'
import {image_base_url} from '@/common/config/index.js'
export default {
  name: '',
  components: {CompaniesList},
  data() {
    var checkValidImg = (rule, value, callback) => {
      // 图片验证
      var height = 500;
      var width = 1000;
      let image = new Image();
      image.src = this.imgHeaderInit + this.ruleForm.logo;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          callback(new Error('Please upload 1000 × 500 pictures'));
        } else {
          callback();
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            callback(new Error('Please upload 1000 × 500 pictures'));
          } else {
            callback();
          }
        };
      }
    };
    return {
      imgHeaderInit: image_base_url,
      showBtnDealImg: false,
      // dialog显示
      dialogVisible: false,
      dialogImageVisible: false,
      dialogImageUrl: '',
      // 添加弹框表单
      ruleForm: {
        enterpriseName: '',
        enterpriseId: '',
        logo: '',
        url: ''
      },
      //   表单验证
      rules: {
        enterpriseId: [
          {
            required: true,
            message: 'Enter the business id',
            trigger: 'change'
          }
        ],
        logo: [
          { required: true, message: 'upload pictures', trigger: 'change' },
          { validator: checkValidImg, trigger: 'blur' }
        ],
        enterpriseName: [
          {
            required: true,
            message: 'Enter the business name',
            trigger: 'change'
          }
        ]
      },
      // 图片上传地址
      http: process.env.VUE_APP_BASE_API + '/system/ware/file/uploadImg',
      codeFile: '',
      codeFileList: '',
      hideCode: false,
      linmitCode: 1,
      eleId: false,
      category: false,
      flieList: [],
      data: [],
      limitCode: 1
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 文件成功时
    successFile(response, file, fileList) {
      if (response.code == 200) {
        this.ruleForm.logo = response.msg;
        this.checkoutImgFn()
      } else {
        this.$message({ message: response.msg, type: 'error' });
      }
    },
    errorImgFile(response) {
      this.ruleForm.logo = '';
      this.flieList = [];
      this.codeRemove({},this.flieList)
      this.$message({ message: response, type: 'error' });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      if (!isJPG) {
        this.$message.error('Uploaded images can only be in JPG or PNG format!');
      }
      return isJPG;
    },
    // 检验图片的方法
    checkoutImgFn() {
      let than = this;
      var height = 500;
      var width = 1000;
      let image = new Image();
      image.src = this.imgHeaderInit + this.ruleForm.logo;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          than.$message.error('Please upload 1000 × 500 pictures');
          than.ruleForm.logo = '';
          than.flieList = [];
          than.codeRemove({},than.flieList)
          return;
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            than.$message.error('Please upload 1000 × 500 pictures');
            than.ruleForm.logo = '';
            than.flieList = [];
            than.codeRemove({},than.flieList)
            return;
          }
        };
      }
    },
    //查看缩略图
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    //提交验证调用添加接口
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.enterpriseId = parseInt(this.ruleForm.enterpriseId);
          Add(this.ruleForm).then(res => {
            if (res.code === 200) {
              this.$parent.getList();
              this.$refs[formName].resetFields();
              this.$refs['my-upload'].clearFiles();
              this.flieList = [];
              this.showBtnDealImg = this.flieList.length >= this.linmitCode;
              this.dialogVisible = false;
              this.eleId = false;
              this.category = false;
              this.$message({
                message: 'Operation succeeded',
                type: 'success'
              });
            } else {
              this.$message({
                message: 'The route addition failed',
                type: 'error'
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 取消的时候
    cancelForm(formName) {
      this.eleId = false;
      this.category = false;
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
      this.$refs['my-upload'].clearFiles();
      // console.log(this.flieList);
      this.flieList = [];
      this.showBtnDealImg = this.flieList.length >= this.linmitCode;
    },
    seletFromFn(row){
      this.ruleForm.enterpriseName = row.enterpriseName;
      this.ruleForm.enterpriseId = row.id;
    },
    // 图片上传时清楚校验规则
    codeChange(file, fileList) {
      this.codeFile = file;
      this.codeFileList = fileList;
      if (fileList.length == 1) {
        let { logo, ...data } = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('logo');
      this.showBtnDealImg = fileList.length >= this.limitCode;
    },
    codeRemove(file, fileList) {
      if (fileList.length == 0) {
        this.rules.logo = [
          { required: true, message: 'upload pictures', trigger: 'change' }
        ];
      }
      this.showBtnDealImg = fileList.length >= this.limitCode;
    },
    // 点击数返回的数据
    handleNodeClick(data) {
      this.ruleForm.eleId = data.id;
      this.ruleForm.cateLevel = data.level;
      this.ruleForm.eleName = data.name;
    },
    skuid(val) {
      if (val == 1) {
        this.eleId = !this.eleId;
        this.category = false;
        this.ruleForm.eleId = null;
      } else {
        this.category = !this.category;
        this.eleId = false;
        this.ruleForm.eleName = null;
      }
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
::v-deep .hideUploadEdit {
  .el-upload--picture-card {
    display: none;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 148px;
    height: 148px;
    line-height: 146px;
    vertical-align: top;
  }
}
::v-deep .el-dialog__body {
  padding: 8px 16px 16px;
  
}

.specialDialog{
  .createLogoContainer{
    .companiesListCont{
      border-top: 16px solid rgb(239, 240, 244);
      padding-top: 24px;
      width: 100%;
    }
    .centerText {
      margin-left: 60px;
      font-size: 14px;
      font-family: ArialMT;
      color: #9398a4;
    }
    .dialog-top{
      .el-input{
          width: 350px;
        }
    }
    .dialog-footer{
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
